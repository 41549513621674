export const OPTIONS_COLORS = [
  "#FF4B91",
  "#FF7676",
  "#FFCD4B",
  "#9ce350",
  "#06D001",
  "#40E0D0",
  "#00CCDD",
  "#48a1ee",
  "#4F75FF",
  "#6439FF",
  "#0802A3",
];
